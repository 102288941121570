<template>
  <v-container fluid class="py-0">
    <h2>Үнэлгээ2 эxэлсэн Monitoring</h2>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="red"
    ></v-progress-linear>
    <v-data-table
      v-else-if="scores"
      :headers="headerNames"
      :items="scores"
      :items-per-page="-1"
    >
      <template v-slot:item.index="{ item }">
        <span @click="print(item)">{{ item.index }}</span>
      </template>
      <template v-slot:item.name="{ item }">
        <span>{{ item.FIRST_NAME + ", " + item.LAST_NAME }}</span>
      </template>
    </v-data-table>
    <div v-else>Empty</div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import moment from "moment";
export default {
  data: () => ({
    scores: null,
    loading: false,
    headerNames: [
      {
        text: "ID",
        align: "start",
        width: "1%",
        value: "index",
      },
      {
        text: "Name",
        value: "FIRST_NAME",
      },
      {
        text: "Бүлэг",
        value: "STUDENT_GROUP_NAME",
      },
      {
        text: "school",
        value: "schoolName",
      },
      {
        text: "Graded By",
        value: "gradedBy.DISPLAY_NAME",
      },
      { text: "Year&Semester", value: "unelgeeDate" },
      {
        text: "createdAt",
        value: "createdAtDate",
      },
    ],
  }),
  props: {},
  components: {},
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    fb.db
      .collectionGroup("scores")
      .limit(25)
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        this.scores = [];
        this.loading = true;
        var counter = 1;
        docs.forEach((doc) => {
          let score = doc.data();
          score.ref = doc.ref;
          score.id = doc.id;
          score.index = counter++;
          score.unelgeeDate = score.year1 + ", " + score.semester;
          score.createdAtDate = new Date(score.createdAt.toDate() - 86400000);
          this.scores.push(score);
        });
        this.loading = false;
        console.log(this.scores);
      });
  },
  methods: {},
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>
